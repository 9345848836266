import * as aiqiyi from './aiqiyi.js'
import * as youku from './youku.js'
import * as tengxun from './tengxun.js'
import * as mangguo from './mangguo.js'
import * as fantuantv from './fantuantv.js'
import * as db from './db.js'
// import * as prefixes from './prefixes'

export default{
    aiqiyi,
    youku,
    tengxun,
    mangguo,
    fantuantv,
    db
}